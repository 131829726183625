/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React from "react";
import * as s from "./CheckInbox.module.scss";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { navigate } from "gatsby-link";

const CheckInbox = () => {
  return (
    <div className={s.container}>
      <p className={s.title}>
        Check your <span>inbox</span>
      </p>
      <p className={s.descritpion}>
        Please check your inbox and click on received link to reset your
        password
      </p>

      <Player
        autoplay
        loop
        src="https://assets4.lottiefiles.com/packages/lf20_qb9smioh.json"
        style={{ height: "136px", width: "136px" }}
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>

      <p className={s.login} onClick={() => navigate("/forgot-password")}>
        Didn’t receive the link? <span>Resend</span>
      </p>
      {/* <button className={s.button} onClick={() => navigate("/reset-password")}>
        Login
      </button> */}
    </div>
  );
};

export default CheckInbox;
