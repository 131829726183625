/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import { useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby-link";
import React, { useEffect, useState } from "react";
import { RECOVER_EMAIl } from "../../../g/mutations/customer";
import { GET_USER } from "../../../g/queries/get-user-info";
import * as s from "./ForgotYourPassword.module.scss";

interface IForgot {
  setSendLink: (a: boolean) => void;
}

const ForgotYourPassword = ({ setSendLink }: IForgot) => {
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const { data } = useQuery(GET_USER, {
    variables: {
      customerAccessToken: token,
    },
    onCompleted: ({ customer }) => {
      if (customer?.email) setEmail(customer?.email);
    },
  });

  const [resetEmail] = useMutation(RECOVER_EMAIl, {
    variables: {
      email: email,
    },
    onCompleted: (data) => {
      if (data?.customerRecover?.customerUserErrors.length > 0) {
        setInvalidEmail(data?.customerRecover?.customerUserErrors[0].message);
      } else setSendLink(true);
    },
  });

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmail(e.target.value);
  };

  const handleClick = () => {
    if (email != "") resetEmail();
    else setInvalidEmail("Please enter your email address");
  };

  return (
    <div className={s.container}>
      <p className={s.title}>
        Forgot <span>password?</span>
      </p>
      <p className={s.descritpion}>
        Enter your registered email below to receive password reset instuction.
      </p>

      <p className={s.label}>Email address</p>

      <div className={s.inputWrapper}>
        <input
          type="text"
          className={s.input}
          onChange={(e) => handleChange(e)}
          placeholder="Enter your email"
        ></input>

        {invalidEmail && (
          <p className={s.errorHandling}>
            <span>*</span>
            {invalidEmail}.
          </p>
        )}
      </div>

      <p className={s.login} onClick={() => navigate("/login")}>
        Back to login
      </p>

      <button className={s.button} onClick={handleClick}>
        Send reset link
      </button>
    </div>
  );
};

export default ForgotYourPassword;
