import React from "react";
import * as s from "./NewHereSection.module.scss";
import backgroundImage from "../../../images/LoginBGImage.png";
import { navigate } from "gatsby";

const NewHereSection = ({ descritpion }: { descritpion: string }) => {
  return (
    <div className={s.container}>
      <img src={backgroundImage} alt=""></img>
      <div className={s.wrapper}>
        <p className={s.title}>
          NEW <span>HERE?</span>
        </p>
        <p className={s.description}>{descritpion}</p>
        <button
          onClick={() => {
            navigate("/products");
          }}
        >
          Shop All
        </button>
      </div>
    </div>
  );
};

export default NewHereSection;
