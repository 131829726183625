import React from "react";
import ForgotPassword from "../components/organisms/ForgotPassword/ForgotPassword";
import { PageTitle, SEO } from "../modules/seo";

const ForgotPasswordPage = () => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        margin: "0 5%",
      }}
    >
      <SEO title={PageTitle.ForgotPassword} />
      <ForgotPassword />
    </div>
  );
};

export default ForgotPasswordPage;
