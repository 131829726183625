/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from "react";
import NewHereSection from "../../atoms/NewHereSection/NewHereSection";
import CheckInbox from "../../molecules/CheckYourInbox/CheckYourInbox";
import ForgotYourPassword from "../../molecules/ForgotYourPassword/ForgotYourPasswoed";
import * as s from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const [sendLink, setSendLink] = useState<boolean>(false);
  return (
    <div className={s.container}>
      <div className={s.leftWrapper}>
        {sendLink === false ? (
          <ForgotYourPassword setSendLink={setSendLink} />
        ) : (
          <CheckInbox />
        )}
      </div>
      <div className={s.rightWrapper}>
        <NewHereSection descritpion="Not a subscriber? Explore our site!" />
      </div>
    </div>
  );
};

export default ForgotPassword;
